import {
    Button,
    Row,
    Col,
    Pagination,
    Dialog,
    Input,
    Form,
    FormItem,
    Checkbox,
    Table,
    TableColumn,
    Radio,
    RadioGroup,
    Select,
    Option,
    Carousel,
    CarouselItem,
    Breadcrumb,
    BreadcrumbItem,
    Image
} from 'element-ui'


const coms = [
    Button,
    Row,
    Col,
    Pagination,
    Dialog,
    Input,
    Form,
    FormItem,
    Checkbox,
    Table,
    TableColumn,
    Radio,
    RadioGroup,
    Select,
    Option,
    Carousel,
    CarouselItem,
    Breadcrumb,
    BreadcrumbItem,
    Image
]


export default {
    install(Vue, options) {
        coms.map(c => {
            Vue.component(c.name, c)
        })
    }
}