export default {
    menu:{
        home:"Home",
        product:"Product",
        category:"Category",
        consult:"Consult",
        contactus:"Contact Us",
        aboutUs:"About Us"
    },
    language:{
        en:'English',
        zh:'中文'
    },
    home:{
        tel:"Tel",
        email:"Email",
        searchPlaceholder:"Please Search By Part Number",
        briefIntroduction:"JunHeng is A distributor of Electronic Components for The Suppliers. ",
        briefContent:`You can search part number or keyword to found products or click on a "products" to find out more about them and how you can place an order or inquiry for the pric.`,
        recommendProducts:"Recommend Products",
        hotProducts:"Hot Products",
        manufacturer:"Manufacturer",
        inventory:"Inventory",
        detail:"Detail",
        subscribeTitle:"Subscribe for the latest information",
        byEmail:"(by email)",
        subscribe:"Subscribe"
    },
    category:{
        allCategory:"All Category"
    },
    contact:{
        title:"Aout JH Technology",
        aboutJH1:"We have been a supplier of electronic components for at least 19 years，JUNHENG electronic was established in 2014.",
        companyContent:`We can help you solve the problem of purchasing electronic components at
                        a low cost.We maintain a sincere and pragmatic attitude in our operations,
                        providing high-quality products and cooperating with customers to
                        enable them to successfully develop and produce products.`,
        companyHope:"We hope Junheng Electronics can become the best partner with you！",
        contactUs:"Contact Us",
        contactBefore:"It's our pleasure provide services for you！You can contact us in the following ways."
    },
    consult:{
        consultTop:"Submit consulting information.We will send the consultation reply by email.",
        productInformation:"Product Information",
        add:"ADD",
        labelID:"ID",
        labelProductNumber:"*Product Number",
        labelBrand:"Brand",
        labelCount:"Count",
        labelTargetPrice:"*Target Price($)",
        labelNote:"Note",
        labelOption:"Option",
        labelYourInfo:"Your Info",
        labelName:"Name",
        labelGender:"Gender",
        labelCompanyName:"Company Name",
        labelReceivingMode:"Receiving Mode",
        email:"Email",
        phone:"Phone Number",
        QQ:"QQ",
        male:"Male",
        lady:"Lady",
        checkPhone:"Please input correct phone number!",
        emptyEmail:"The email cannot be empty",
        checkEmail:"Please enter the correct email!",
        checkLastName:"Please enter your last name",
        mostInquiries:"No more than 3 inquiries at the same time！"
    },
    product:{
        emptyData:"No data could be found",
        return:"Return",
        thProductName:"Product Name",
        thManuFacture:"ManuFacture",
        thDescription:"Description",
        thCount:"Count",
        thOption:"Option",
        detailTitle:"Product",
        detailSub:" / Product Detail",
        labelProductName:"Product Number : ",
        labelManufacture:"Manufacture : ",
        labelClass:"Class : ",
        labelEncapsulation:"Encapsulation : ",
        otherInfo:"Other Info",
        labelYear:"Year of production : ",
        labelCount:"Count : ",
        labelDescription:"Description : ",
        correlation:"Correlation"
    },
    footer:{
        workHour:"Work Hours： 9:30am To 6:00pm From Monday To Firday",
        contactWay:"Email：",
        companyIntroduction:"Started to be an electronic component distributor in 2006"
    },
    common:{
        more:"More",
        detail:"Detail",
        remove:"remove",
        subbmit:"Subbmit",
        reset:"Reset"
    }
}