<template>
  <div id="app">
    <!-- 一级路由 -->
    <router-view />
  </div>
</template>

<script>
import { addVisitAPI,IPexistAPI,allowVisitAPI } from "@/api/index";
export default {
  async mounted() {
    this.addVisitRecord()
  },

  methods:{
    async checkIPExist(){
      const res = await IPexistAPI()
      console.log(res);
    },
    async addVisitRecord(){
      const res = await addVisitAPI()
      console.log(res);
    }
  }
};
</script>

<style>
</style>