import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/home'
// import Product from '@/views/product'
// import Main from '@/views/main'
// import Ours from '@/views/ours'
// import Consult from '@/views/consult'
// import Contact from '@/views/contact'
// import Category from '@/views/category'
// import ProductDetail from '@/views/product/ProductDetail'

const Home = () => import('@/views/home')
const Main = () => import('@/views/main')
const Ours = () => import('@/views/ours')
const Consult = () => import('@/views/consult')
const Contact = () => import('@/views/contact')
const Category = () => import('@/views/category')
const ProductDetail = () => import('@/views/product/ProductDetail')
const Product = () => import('@/views/product')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '/',
        name: 'Main',
        component: Main,
      },
      {
        path: '/product',
        name: 'Product',
        component: Product
      },
      {
        path: '/ours',
        name: 'Ours',
        component: Ours
      },
      {
        path: '/category',
        name: 'Category',
        component: Category
      },
      {
        path: '/consult',
        name: 'Consult',
        component: Consult
      },
      {
        path: '/contact',
        name: 'Contact',
        component: Contact
      },
      {
        path: '/product/productdetail',
        name: 'ProductDetail',
        component: ProductDetail
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
