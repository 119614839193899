import axios from 'axios'

// dev 开发环境
// prd 生产环境
let mode = 'prd'

const instance = axios.create({
    baseURL: mode == 'dev' ? 'http://localhost:3000' : 'https://jhtech-ic.com:5000'
})

instance.interceptors.request.use(
    config => {
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
instance.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        return Promise.reject(error)
    }
)

export default instance

