import Vue from 'vue'

export default {
    install(Vue){
        Vue.directive('unReClick', {
            inserted:function(el,binding){
              el.addEventListener('click', () => {
                                if (el.style['pointer-events'] != 'none') {
                                    el.style['pointer-events'] = 'none';
                                    setTimeout(() => {
                                        el.style['pointer-events'] = 'all';
                                    }, binding.value || 1500)
                                }
                            })
             }
          })
    }
}