<template>
  <div class="openpdf">
    <p @click="openpdf">openpdf</p>
  </div>
</template>

<script>
export default {
  methods:{
    openpdf(){
      window.open('/stm32h743bi.pdf')
    }
  }
}
</script>

<style>

</style>