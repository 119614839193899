import instance from '@/utils/request'

//获取推荐列表产品
export function getRecProAPI(params) {
    return instance({
        url: '/inventory/getRecPro',
        method: 'GET',
        params
    })
}

//获取热销产品数据
export function getHotProAPI(params) {
    return instance({
        url: '/inventory/getHotPro',
        method: 'GET',
        params
    })
}

//获取轮播图数据
export function getSwipersAPI(params) {
    return instance({
        url: '/swipers/getSwipers',
        method: 'GET',
        params
    })
}

//获取产品数据
export function getInventoryAPI(params) {
    return instance({
        url: '/inventory/getInventory',
        method: 'GET',
        params
    })
}

//新增询价记录
export function newEnquiryAPI(data) {
    return instance({
        url: '/enquiry/newEnquiry',
        method: 'POST',
        data
    })
}

// 获取分类数据
export function schClasstypeAPI() {
    return instance({
        url: '/class/schClasstype'
    })
}

//订阅
export function newSubscribeAPI(data) {
    return instance({
        url: '/subscribe/subscribeAdd',
        method: 'POST',
        data
    })
}

//根据封装推送相关产品
export function getCorrelationAPI(params) {
    return instance({
        url: '/inventory/correlation',
        method: 'GET',
        params
    })
}

// 获取分类数据2.0
export function selectCategoryAPI() {
    return instance({
        url: '/category/selectCate'
    })
}

// 是否允许访问
export function allowVisitAPI(){
    return instance({
        url: `/monitorvisit/allowVisit`
    })
}

// 是否已经有该IP记录
export function IPexistAPI(){
    return instance({
        url: `/monitorvisit/IPexist`
    })
}

// 更新记录
export function addVisitAPI(){
    return instance({
        url:`/monitorvisit/addVisit`
    })
}