import VueI18n from "vue-i18n";
import Vue from "vue";
import en from "./modules/en";
import zh from "./modules/zh";

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale:localStorage.getItem("language") || "en",
    messages:{
        en:{
            ...en
        },
        zh:{
            ...zh
        }
    }
})

export default i18n